/* eslint-disable @typescript-eslint/no-empty-function */
import NavBar from "../components/Navbar";
import useStyles from "../styles/HowToArriveStyles";
import { newHomeNavBar } from "../utils";

export default function BasicServices() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavBar scrollToSection={() => {}} externalSections={newHomeNavBar} />
      <div>
        <p>En construcción...</p>
      </div>
    </div>
  );
}
