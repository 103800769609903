import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ErrorMessage, SharedState } from "./types/shared";

const initialState: SharedState = {
  error: undefined,
};

const sharedSlice = createSlice({
  name: "shared",
  initialState,
  reducers: {
    errorMessage: (state, action: PayloadAction<ErrorMessage>) => {
      state.error = action.payload.error;
    },
  },
});

export const { errorMessage } = sharedSlice.actions;
export default sharedSlice;
