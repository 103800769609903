import { combineReducers, configureStore } from "@reduxjs/toolkit";

import activitiesApi from "./api/activities";
import categoriesApi from "./api/categories";
import locationsApi from "./api/locations";
import multimediaApi from "./api/multimedia";
import serviceProvidersApi from "./api/serviceProviders";
import subCategoriesApi from "./api/subCategories";

const stores = {
  [activitiesApi.reducerPath]: activitiesApi.reducer,
  [subCategoriesApi.reducerPath]: subCategoriesApi.reducer,
  [locationsApi.reducerPath]: locationsApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [multimediaApi.reducerPath]: multimediaApi.reducer,
  [serviceProvidersApi.reducerPath]: serviceProvidersApi.reducer,
};

const middlewares = [
  activitiesApi.middleware,
  subCategoriesApi.middleware,
  locationsApi.middleware,
  categoriesApi.middleware,
  multimediaApi.middleware,
  serviceProvidersApi.middleware,
];

const rootReducer = combineReducers(stores);

export const Store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
    }).concat(middlewares),
});

export type RootState = ReturnType<typeof Store.getState>;

export type AppDispatch = typeof Store.dispatch;
