import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import useStyles from "../styles/FacebookReviewsSectionStyles";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function WhoWeAreSection() {
  const classes = useStyles();

  const theme = useTheme();

  const [activeStep, setActiveStep] = useState<number>(0);

  const reviews = [
    <iframe
      title="review-0"
      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2Fignacio.b.villegas%2Fposts%2F10226937511400894&show_text=true&width=300&height=188"
      width="300"
      height="188"
      className={classes.iframe}
      style={{ height: 188 }}
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />,
    <iframe
      title="review-1"
      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2Fpermalink.php%3Fstory_fbid%3D1328205710937922%26id%3D100012455166383&show_text=true&width=300&height=215"
      width="300"
      height="215"
      className={classes.iframe}
      style={{ height: 215 }}
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />,
    <iframe
      title="review-2"
      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2FWellOfSadness%2Fposts%2F10160624233174009&show_text=true&width=300"
      width="300"
      height="215"
      className={classes.iframe}
      style={{ height: 215 }}
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />,
    <iframe
      title="review-3"
      src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fweb.facebook.com%2Fvaale.lagos%2Fposts%2F10227422626130012&show_text=true&width=300"
      width="300"
      height="680"
      className={classes.iframe}
      style={{ height: 680 }}
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />,
  ];

  const maxSteps = reviews.length;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <Stack spacing={2} className={classes.stack}>
        <Typography variant="h1" component="h1" align="center" gutterBottom>
          Nuestros usuarios nos recomiendan
        </Typography>
        <Box sx={{ maxWidth: 300, flexGrow: 1, border: 1 }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            interval={6000}
            enableMouseEvents
          >
            {reviews.map((step, index) => (
              <div key={`review-${step}`}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    sx={{
                      display: "block",
                      maxWidth: 300,
                      overflowX: "hidden",
                      overflowY: "scroll",
                      width: "100%",
                      height: 200,
                      marginTop: "auto",
                    }}
                  >
                    {step}
                  </Box>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
            }
          />
        </Box>
      </Stack>
    </div>
  );
}
