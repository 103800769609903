import { Button, CardActionArea, CardActions } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import useGetActivityRelatedData from "../hooks/useGetActivityRelatedData";
import { Activity } from "../services/types/activities";
import { hoursToText, parsePrice } from "../utils";
import CardDialog from "./CardDialog";

interface Props {
  activity: Activity;
}

export default function ActivityCard({ activity }: Props) {
  const { activity_name, sale_price, price_per, duration_hours, schedule } =
    activity;
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const time = hoursToText(duration_hours);

  const [searchParams, setSearchParams] = useSearchParams();

  const [
    getActivityRelatedData,
    {
      data: { subCategory, multimedia, serviceProvider },
      isFetching,
    },
  ] = useGetActivityRelatedData();

  useEffect(() => {
    getActivityRelatedData(activity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]);

  if (isFetching) return <Typography>Cargando...</Typography>;

  return (
    <Card sx={{ maxWidth: 300, margin: "auto" }}>
      {multimedia.length > 0 && (
        <CardMedia
          component="img"
          height="160"
          image={multimedia[0].link || undefined}
          alt={activity_name}
          onClick={() => setOpen(true)}
          sx={{
            "&:hover": { cursor: "pointer" },
          }}
        />
      )}
      <CardContent
        onClick={() => setOpen(true)}
        sx={{
          "&:hover": { cursor: "pointer" },
        }}
      >
        <Typography variant="h6">{activity_name}</Typography>
        <Typography color="text.secondary">
          {sale_price ? parsePrice(sale_price, price_per || "") : ""}
        </Typography>
        <Typography>{time}</Typography>
        <Typography>{schedule}</Typography>
      </CardContent>
      <CardActionArea>
        <CardActions>
          <Button variant="text" onClick={() => setOpen(true)}>
            Más información
          </Button>
          <CardDialog
            open={open}
            onClose={() => {
              searchParams.delete("activity");
              setSearchParams(searchParams);
              setOpen(false);
            }}
            activity={activity}
            subCategory={subCategory}
            multimedia={multimedia}
            serviceProvider={serviceProvider}
            smallScreen={window.innerWidth < 900}
            openImage={openImage}
            onCloseImage={() => setOpenImage(false)}
            onOpenImage={() => setOpenImage(true)}
          />
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
