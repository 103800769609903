import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  BasicServices,
  Benefits,
  Blog,
  HowItWorks,
  HowToArrive,
  NewHome,
} from "./pages";

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<NewHome />} />
      <Route path="/como-llegar-a-futaleufu" element={<HowToArrive />} />
      <Route path="/como-funciona" element={<HowItWorks />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/basic-services" element={<BasicServices />} />
      <Route path="/benefits" element={<Benefits />} />
    </Routes>
  </Router>
);

export default AppRouter;
