/* eslint-disable import/no-extraneous-dependencies */
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import HowToArriveByCarSection from "../components/HowToArriveByCarSection";
import HowToArriveByFootSection from "../components/HowToArriveByFootSection";
import NavBar from "../components/Navbar";
import useStyles from "../styles/HowToArriveStyles";
import { scrollToSection } from "../utils";

const textStyles = {
  marginTop: 5,
};

export default function LandingPage() {
  const classes = useStyles();

  const navigate = useNavigate();

  const arriveByCarSectionRef = useRef(null);
  const arriveByCarSeaRef = useRef(null);
  const arriveByCarLandRef = useRef(null);
  const arriveByFootSectionRef = useRef(null);
  const arriveByFootSeaRef = useRef(null);
  const arriveByFootAirRef = useRef(null);

  const sections = [
    { text: "Llegar en vehículo", ref: arriveByCarSectionRef },
    { text: "Llegar a pie", ref: arriveByFootSectionRef },
  ];

  const externalSections = [
    { text: "¿Cómo funciona?", link: "/como-funciona" },
    { text: "Volver al inicio", link: "/" },
  ];

  return (
    <div className={classes.root}>
      <NavBar
        sections={sections}
        scrollToSection={scrollToSection}
        externalSections={externalSections}
      />
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="info"
            fullWidth
            size="large"
            onClick={() => navigate("/")}
          >
            Quiero reservar hospedaje y/o actividades de turismo
          </Button>
        </div>
        <Typography variant="h1" component="h1" className={classes.title}>
          ¿Cómo llegar a Futaleufú?
        </Typography>
        <Typography variant="subtitle1" sx={textStyles as SxProps}>
          Para llegar tienes distintas alternativas:
        </Typography>
        <List>
          <ListItemButton
            onClick={() => scrollToSection(arriveByCarSectionRef)}
          >
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
            <ListItemText primary="Si vienes en vehículo" />
          </ListItemButton>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => scrollToSection(arriveByCarSeaRef)}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Vía marítima" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => scrollToSection(arriveByCarLandRef)}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Vía terrestre" />
            </ListItemButton>
          </List>
          <ListItemButton
            onClick={() => scrollToSection(arriveByFootSectionRef)}
          >
            <ListItemIcon>
              <ArrowForwardIosIcon />
            </ListItemIcon>
            <ListItemText primary="Si vienes a pie" />
          </ListItemButton>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => scrollToSection(arriveByFootSeaRef)}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Vía marítima" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => scrollToSection(arriveByFootAirRef)}
            >
              <ListItemIcon>
                <ArrowRightIcon />
              </ListItemIcon>
              <ListItemText primary="Vía aérea" />
            </ListItemButton>
          </List>
        </List>
        <HowToArriveByCarSection
          sectionRef={arriveByCarSectionRef}
          textStyles={textStyles}
          subSectionRefs={[arriveByCarSeaRef, arriveByCarLandRef]}
        />
        <HowToArriveByFootSection
          sectionRef={arriveByFootSectionRef}
          textStyles={textStyles}
          subSectionRefs={[arriveByFootSeaRef, arriveByFootAirRef]}
        />
        <Typography variant="body2" sx={textStyles as SxProps}>
          Si aún tienes dudas o necesitas más indicaciones, puedes escribirnos
          al WhatsApp o a{" "}
          <a href="mailto:contacto@easytrip.cl">contacto@easytrip.cl</a>
        </Typography>
      </div>
      <Footer />
    </div>
  );
}
