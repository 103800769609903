/* eslint-disable @typescript-eslint/no-empty-function */
import BenefitsSection from "../components/BenefitsSection";
import NavBar from "../components/Navbar";
import useStyles from "../styles/HowToArriveStyles";
import { newHomeNavBar } from "../utils";

export default function Benefits() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavBar scrollToSection={() => {}} externalSections={newHomeNavBar} />
      <BenefitsSection />
    </div>
  );
}
