const root = {
  pt: 10,
  maxWidth: 1200,
  mx: "auto",
  px: 5,
};

const styles = {
  root,
};

export default styles;
