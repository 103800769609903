import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    titleContainer: {
      display: "flex",
      alignItems: "center",
      padding: 20,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
    },
  }),
);

export default useStyles;
