import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    brand: {
      flexGrow: 1,
    },
    logo: {
      maxHeight: 40,
      marginTop: 10,
      "&:hover": {
        cursor: "pointer",
      },
    },
    drawer: {
      alignItems: "flex-end",
    },
    button: {
      fontSize: 60,
    },
  }),
);

export default useStyles;
