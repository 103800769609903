/* eslint-disable import/no-extraneous-dependencies */
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";

import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import useStyles from "../styles/HowItWorksStyles";
import { scrollToSection } from "../utils";

const textStyles = {
  marginTop: 3,
};

const headerStyles = {
  marginTop: 8,
};

export default function LandingPage() {
  const classes = useStyles();

  const navigate = useNavigate();

  const externalSections = [
    { text: "Cómo llegar", link: "/como-llegar-a-futaleufu" },
    { text: "Volver al inicio", link: "/" },
  ];

  return (
    <div className={classes.root}>
      <NavBar
        scrollToSection={scrollToSection}
        externalSections={externalSections}
      />
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="info"
            fullWidth
            size="large"
            onClick={() => navigate("/")}
          >
            Quiero reservar hospedaje y/o actividades de turismo
          </Button>
        </div>
        <Typography variant="h1" component="h1" className={classes.title}>
          ¿Cómo funciona?
        </Typography>
        <Typography variant="h2" component="h2" sx={headerStyles as SxProps}>
          Cotización
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Rellena el formulario con los datos solicitados, seleccionando los
          servicios que deseas cotizar y el intervalo de fechas en los que
          quieras realizarlos.
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Realizado el paso anterior, nosotros te enviaremos a tu correo la
          cotización correspondiente, en donde indicaremos el valor de cada
          servicio, lo que incluye y las políticas de cancelación.
        </Typography>
        <Typography variant="h2" component="h2" sx={headerStyles as SxProps}>
          Reserva
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Si estás de acuerdo con la cotización recibida, te enviaremos un link
          de pago para que puedas realizar tu reserva.
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          El abono es de un 50% si deseas reservar con 4 o más días de
          anticipación, el pago del 50% restante se debe hacer 3 días antes de
          la fecha del primer servicio que vas a realizar. En caso de que el
          periodo de reserva sea entre 1 y 3 días de anticipación, deberás pagar
          el 100% de los servicios seleccionados.
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          La reserva debe ser realizada con al menos 24 horas de anticipación.
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Nosotros nos encargaremos de que puedas realizar cada actividad
          solicitada sin que tengas la necesidad de estar buscando a cada
          empresa, déjale ese trabajo a Easy Trip y disfruta al máximo tu
          estancia en Futaleufú.
        </Typography>
        <Typography variant="h2" component="h2" sx={headerStyles as SxProps}>
          Beneficios
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Al reservar a través de la plataforma de Easy Trip podrás acceder a
          diversos beneficios que tengamos disponibles en el momento, como
          descuentos y/o regalos en el comercio local.
        </Typography>
        <Typography variant="h2" component="h2" sx={headerStyles as SxProps}>
          Métodos de pago
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Contamos con Mercado Pago, en dónde podrás pagar con tarjeta de
          crédito, débito o con cuenta de mercado pago. También puedes realizar
          transferencia electrónica. NO aceptamos efectivo.
        </Typography>
        <Typography variant="h2" component="h2" sx={headerStyles as SxProps}>
          Proveedores externos
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Easy Trip busca ser un apoyo para los emprendedores locales, es por
          esto que todos los servicios ofrecidos en esta plataforma son
          realizados por proveedores externos, quienes cuentan con la
          documentación al día para la realización de estas actividades y son
          los/as encargados/as de brindar el servicio.
        </Typography>
        <Typography variant="h2" component="h2" sx={headerStyles as SxProps}>
          Cancelación de reserva
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          En caso de que necesites cancelar uno o más servicios, nos acogeremos
          a la política de cancelación que tenga cada servicio cotizado, la cual
          estará detallada en la cotización correspondiente
        </Typography>
        <Typography variant="h2" component="h2" sx={headerStyles as SxProps}>
          Flexibilidad
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Sabemos que las condiciones climáticas y/o problemas para llegar
          pueden jugar en contra para realizar las actividades solicitadas. Si
          ocurre alguno de estos percances puedes contactarte con nosotros en{" "}
          <a href="mailto:contacto@easytrip.cl">contacto@easytrip.cl</a> y te
          daremos alternativas para re-agendar los servicios reservados.
        </Typography>
        <Typography variant="h2" component="h2" sx={headerStyles as SxProps}>
          Costo de los servicios
        </Typography>
        <Typography variant="body2" sx={textStyles as SxProps}>
          Cada servicio tiene un valor diferente según el tipo de actividad que
          realices o el tiempo que desees estar en estas. Puedes ver el precio
          tentativo en la sección de precios de la{" "}
          <Link to="/">página principal</Link> de este sitio.
        </Typography>
      </div>
      <Footer />
    </div>
  );
}
