/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

import ActivitiesSection from "../components/ActivitiesSection";
import CardDialog from "../components/CardDialog";
import Carousel from "../components/Carousel";
import NavBar from "../components/Navbar";
import SearchBox from "../components/SearchBox";
import WhatsappFab from "../components/WhatsappFab";
import { useLazyGetActivityQuery } from "../hooks/api/activities";
import { useLazyGetCategoryQuery } from "../hooks/api/categories";
import { useLazyGetLocationQuery } from "../hooks/api/locations";
import { useLazyGetSubCategoryQuery } from "../hooks/api/subCategories";
import useGetActivityRelatedData from "../hooks/useGetActivityRelatedData";
import { Activity } from "../services/types/activities";
import { Category } from "../services/types/categories";
import { Location } from "../services/types/locations";
import { SubCategory } from "../services/types/subCategories";
import styles from "../styles/NewHomeStyles";
import { newHomeNavBar } from "../utils";

interface Bla {
  location: number | null;
  category: number | null;
  subCategory: number | null;
}

export default function LandingPage() {
  const titleRef = useRef(null);

  const [activityParam, setActivityParam] = useState<string | null>(null);
  const [locationParam, setLocationParam] = useState<string | null>(null);
  const [categoryParam, setCategoryParam] = useState<string | null>(null);
  const [subCategoryParam, setSubCategoryParam] = useState<string | null>(null);
  const [location, setLocation] = useState<Location | null>(null);
  const [category, setCategory] = useState<Category | null>(null);
  const [subCategory, setSubCategory] = useState<SubCategory | null>(null);
  const [openActivity, setOpenActivity] = useState<Activity | null>(null);
  const [openImage, setOpenImage] = useState(false);
  const [isSearchReady, setIsSearchReady] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [fetchLocation] = useLazyGetLocationQuery();
  const [fetchCategory] = useLazyGetCategoryQuery();
  const [fetchSubCategory] = useLazyGetSubCategoryQuery();

  const [fetchActivity] = useLazyGetActivityQuery();
  const [
    getActivityRelatedData,
    {
      data: { subCategory: activitySubCategory, multimedia, serviceProvider },
      isFetching,
    },
  ] = useGetActivityRelatedData();

  const fetchData = useCallback(
    async (activity_id: number) => {
      const response = await fetchActivity({ id: activity_id }).unwrap();
      if (response.id === activity_id) {
        setOpenActivity(response);
        getActivityRelatedData(response);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchActivity],
  );

  useEffect(() => {
    setActivityParam(searchParams.get("activity"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activityParam) {
      const activity_id = Number(activityParam.split("-")[0]);
      if (!Number.isNaN(activity_id)) {
        fetchData(activity_id);
      }
    }
  }, [activityParam, fetchData]);

  useEffect(() => {
    setLocationParam(searchParams.get("location"));
    setCategoryParam(searchParams.get("category"));
    setSubCategoryParam(searchParams.get("sub-category"));
  }, [searchParams]);

  const getLocation = useCallback(async () => {
    const response = await fetchLocation({
      id: Number(locationParam),
    }).unwrap();
    if (response) setLocation(response);
  }, [fetchLocation, locationParam]);

  const getCategory = useCallback(async () => {
    const response = await fetchCategory({
      id: Number(categoryParam),
    }).unwrap();
    if (response) setCategory(response);
  }, [fetchCategory, categoryParam]);

  const getSubCategory = useCallback(async () => {
    const response = await fetchSubCategory({
      id: Number(subCategoryParam),
    }).unwrap();
    if (response) setSubCategory(response);
  }, [fetchSubCategory, subCategoryParam]);

  useEffect(() => {
    if (locationParam) getLocation();
    if (categoryParam) getCategory();
    if (subCategoryParam) getSubCategory();
  }, [
    getLocation,
    getCategory,
    getSubCategory,
    locationParam,
    categoryParam,
    subCategoryParam,
  ]);

  useEffect(() => {
    let isReady = true;
    const locParam = searchParams.get("location");
    const catParam = searchParams.get("category");
    const subCatParam = searchParams.get("sub-category");
    if (locParam && !location) {
      isReady = false;
    }
    if (catParam && !category) {
      isReady = false;
    }
    if (subCatParam && !subCategory) {
      isReady = false;
    }
    setIsSearchReady(isReady);
  }, [category, location, searchParams, subCategory]);

  const onChangeSearch = useCallback(
    (data: Bla) => {
      if (data.location) {
        searchParams.set("location", data.location.toString());
      }
      if (data.category) {
        searchParams.set("category", data.category.toString());
      }
      if (data.subCategory) {
        searchParams.set("sub-category", data.subCategory.toString());
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  return (
    <Box sx={styles.root}>
      <NavBar
        scrollToSection={() => {}}
        sections={[]}
        externalSections={newHomeNavBar}
      />
      <WhatsappFab />
      <Carousel />
      <Typography ref={titleRef} variant="h1" align="center">
        Prepara tus vacaciones rápido y sencillo
      </Typography>
      <Typography align="center">
        Reserva actividades y/o alojamiento y recibe GRATIS beneficios exlusivos
        en el comercio local
      </Typography>
      {isSearchReady && (
        <SearchBox
          location={location}
          category={category}
          subCategory={subCategory}
          onChange={onChangeSearch}
        />
      )}
      {/* <Typography variant="h2" sx={{ marginTop: 5, marginBottom: 5 }}>
        Actividades más recomendadas
      </Typography> */}
      <Box sx={{ marginBottom: 20, marginTop: 10 }}>
        <ActivitiesSection
          location={location}
          category={category}
          subCategory={subCategory}
        />
      </Box>
      {openActivity && !isFetching && (
        <CardDialog
          open={openActivity != null}
          onClose={() => {
            searchParams.delete("activity");
            setSearchParams(searchParams);
            setOpenActivity(null);
          }}
          activity={openActivity}
          subCategory={activitySubCategory}
          multimedia={multimedia}
          serviceProvider={serviceProvider}
          smallScreen={window.innerWidth < 900}
          openImage={openImage}
          onCloseImage={() => setOpenImage(false)}
          onOpenImage={() => setOpenImage(true)}
        />
      )}
      {/* <Typography variant="h2" sx={{ marginTop: 5, marginBottom: 5 }}>
        Actividades más vendidas
      </Typography> */}
    </Box>
  );
}
