import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 80,
      paddingBottom: 80,
      [theme.breakpoints.up("md")]: {
        paddingLeft: 100,
        paddingRight: 100,
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: 300,
        paddingRight: 300,
      },
    },
    infoContainer: {
      padding: 20,
    },
    teamMember: {
      marginBottom: 40,
    },
    avatarRight: {
      marginRight: 40,
    },
    avatarLeft: {
      marginLeft: 40,
    },
    rightAlignedText: {
      textAlign: "right",
    },
    attributesContainer: {
      display: "flex",
      justifyContent: "center",
    },
  }),
);

export default useStyles;
