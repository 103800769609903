import axios, { AxiosResponse } from "axios";

import {
  GetActivitiesArgs,
  GetActivitiesResponse,
  GetActivityArg,
  GetActivityResponse,
} from "./types/activities";

class ActivitiesService {
  static getActivity = async (
    args: GetActivityArg,
  ): Promise<Partial<AxiosResponse<GetActivityResponse>>> =>
    axios.get(`https://easytrip.cl/api/activities/${args.id}`);

  static getActivities = async (
    args: GetActivitiesArgs,
  ): Promise<Partial<AxiosResponse<GetActivitiesResponse>>> => {
    const location = args.location ? `&location=${args.location}` : "";
    const category = args.category ? `&category=${args.category}` : "";
    const subCategory = args.subCategory
      ? `&sub_category=${args.subCategory}`
      : "";
    return axios.get(
      `https://easytrip.cl/api/activities/?page_size=${args.page_size}&page=${args.page}${location}${category}${subCategory}`,
    );
  };
}

export default ActivitiesService;
