/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import "./index.css";

import TimeAgo from "javascript-time-ago";
// eslint-disable-next-line import/extensions
import es from "javascript-time-ago/locale/es.json";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

TimeAgo.addDefaultLocale(es);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
