import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 80,
      backgroundColor: "#2a5927",
      display: "flex",
      justifyContent: "center",
    },
    logo: {
      maxWidth: 120,
    },
  }),
);

export default useStyles;
