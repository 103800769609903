import axios, { AxiosResponse } from "axios";

import {
  GetCategoriesArgs,
  GetCategoriesResponse,
  GetCategoryArgs,
  GetCategoryResponse,
} from "./types/categories";

class CategoriesService {
  static getCategory = async (
    args: GetCategoryArgs,
  ): Promise<Partial<AxiosResponse<GetCategoryResponse>>> =>
    axios.get(`https://easytrip.cl/api/categories/${args.id}`);

  static getCategories = async (
    args: GetCategoriesArgs,
  ): Promise<Partial<AxiosResponse<GetCategoriesResponse>>> =>
    axios.get(
      `https://easytrip.cl/api/categories/?page_size=${args.page_size}&page=${args.page}`,
    );
}

export default CategoriesService;
