import { createApi } from "@reduxjs/toolkit/dist/query/react";

import LocationsService from "../../services/locations";
import {
  GetLocationArgs,
  GetLocationResponse,
  GetLocationsArgs,
  GetLocationsResponse,
} from "../../services/types/locations";
import { apiBaseQuery } from "../utils/store";

const locationsApi = createApi({
  reducerPath: "locationsApi",
  baseQuery: apiBaseQuery(),
  tagTypes: ["Location", "Locations"],
  endpoints: builder => ({
    getLocation: builder.query<GetLocationResponse, GetLocationArgs>({
      query: args => ({ func: LocationsService.getLocation, args }),
      providesTags: (_result, _error, arg) => [
        { type: "Location", id: arg.id },
      ],
    }),
    getLocations: builder.query<GetLocationsResponse, GetLocationsArgs>({
      query: args => ({ func: LocationsService.getLocations, args }),
      providesTags: (_result, _error, arg) => [
        { type: "Locations", id: `${arg.page_size}-${arg.page}` },
      ],
    }),
  }),
});

export default locationsApi;
