import "./App.css";

import { ThemeProvider } from "@mui/material/styles";
import { Provider as StoreProvider } from "react-redux";

import AppRouter from "./AppRouter";
import { Store } from "./store";
import easytripTheme from "./styles/globals";

function App() {
  return (
    <StoreProvider store={Store}>
      <ThemeProvider theme={easytripTheme}>
        <AppRouter />
      </ThemeProvider>
    </StoreProvider>
  );
}

export default App;
