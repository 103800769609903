import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Fab from "@mui/material/Fab";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

const fabStyle = (theme: Theme) => {
  return {
    position: "fixed",
    bottom: 26,
    right: 26,
    width: 60,
    height: 60,
    zIndex: 9999,
    [theme.breakpoints.up("md")]: {
      width: 90,
      height: 90,
    },
  };
};

const iconStyle = (theme: Theme) => {
  return {
    fontSize: 40,
    [theme.breakpoints.up("md")]: {
      fontSize: 60,
    },
  };
};

export default function WhatsappFab() {
  return (
    <a
      href="https://wa.me/56931778303?text=Hola,%20me%20interesa%20contactarme%20con%20ustedes"
      target="_blank"
      rel="noreferrer"
    >
      <Fab color="primary" aria-label="whatsapp" sx={fabStyle as SxProps}>
        <WhatsAppIcon sx={iconStyle as SxProps} />
      </Fab>
    </a>
  );
}
