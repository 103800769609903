import { Card, CardMedia } from "@mui/material";
import MUICarousel from "react-material-ui-carousel";

import futa1 from "../assets/images/carousel/futaleufu1.png";
import futa2 from "../assets/images/carousel/futaleufu2.png";

export default function Carousel() {
  const pictures = [
    { image: futa1, title: "Futaleufu" },
    { image: futa2, title: "Futaleufu" },
  ];

  return window.innerWidth < 900 ? (
    <MUICarousel sx={{ height: 150 }}>
      {pictures.map(({ image, title }) => (
        <Card key={image}>
          <CardMedia
            image={image}
            title={title}
            style={{
              height: 0,
              paddingTop: "25%",
            }}
          />
        </Card>
      ))}
    </MUICarousel>
  ) : (
    <MUICarousel sx={{ height: 340 }}>
      {pictures.map(({ image, title }) => (
        <Card key={image}>
          <CardMedia
            image={image}
            title={title}
            style={{
              height: 0,
              paddingTop: "25%",
            }}
          />
        </Card>
      ))}
    </MUICarousel>
  );
}
