import InstagramIcon from "@mui/icons-material/Instagram";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";

import logoEscarchaBiergarten from "../assets/images/benefits/escarchabiergarten.png";
import logoFronteraSnack from "../assets/images/benefits/fronterasnack.png";
import logoLaRosadita from "../assets/images/benefits/larosadita.jpg";
import logoMeliaDulcesyCafe from "../assets/images/benefits/MeliaDulcesyCafe.png";
import useStyles from "../styles/BenefitsSectionStyles";
import Benefit from "./Benefit";

const iconStyles = {
  fontSize: 50,
  color: "black",
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sectionRef?: React.MutableRefObject<any>;
}

export default function BenefitsSection({ sectionRef }: Props) {
  const classes = useStyles();

  const benefits = [
    {
      name: "La Rosadita",
      logo: logoLaRosadita,
      description:
        "10% de descuento en consumo presencial de comida presentando tu código QR",
      tag: "Restaurant",
      place: "Futaleufú",
      longDescription: (
        <Typography variant="body2">
          <ul>
            <li>
              Accede al 10% de descuento del total de la boleta (sin incluir
              propina)
            </li>
            <li>Descuento aplica sólo a comida, no aplicable a bebestibles</li>
            <li>Válido sólo para consumo dentro del local</li>
            <li>No acumulable con otras promociones</li>
            <li>Descuento exclusivo presentando tu código QR</li>
          </ul>
        </Typography>
      ),
      link: (
        <a
          href="https://www.instagram.com/larosaditafutaleufu/"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <InstagramIcon sx={iconStyles as SxProps} />
        </a>
      ),
      schedule: (
        <div>
          <Typography variant="body2">
            Lunes a Sábado: 11:00 - 15:00; 19:00 - 23:00
          </Typography>
          <Typography variant="body2">Domingo: Cerrado</Typography>
        </div>
      ),
      address: (
        <Typography variant="body2">
          Sargento aldea 342, Futaleufú, Los Lagos
        </Typography>
      ),
    },
    {
      name: "Melia Dulces y Café",
      logo: logoMeliaDulcesyCafe,
      description:
        "10% de descuento en consumo presencial presentando tu código QR",
      tag: "Cafetería",
      place: "Futaleufú",
      longDescription: (
        <Typography variant="body2">
          <ul>
            <li>
              Accede al 10% de descuento del total de la boleta (sin incluir
              propina)
            </li>
            <li>Válido sólo para consumo dentro del local</li>
            <li>No acumulable con otras promociones</li>
            <li>Descuento exclusivo presentando tu código QR</li>
          </ul>
        </Typography>
      ),
      link: (
        <a
          href="https://www.instagram.com/melia.dulcesycafe/"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <InstagramIcon sx={iconStyles as SxProps} />
        </a>
      ),
      schedule: (
        <div>
          <Typography variant="body2">
            Martes a Domingo: 09:00 - 13:00; 15:00 - 22:00
          </Typography>
          <Typography variant="body2">Lunes: Cerrado</Typography>
        </div>
      ),
      address: (
        <Typography variant="body2">
          Pedro Aguirre Cerda 635, Futaleufú, Los Lagos
        </Typography>
      ),
    },
    {
      name: "Frontera Snack Bar Futaleufú",
      logo: logoFronteraSnack,
      description:
        "10% de descuento en consumo presencial de comida presentando tu código QR",
      tag: "Sushi/Restobar",
      place: "Futaleufú",
      longDescription: (
        <Typography variant="body2">
          <ul>
            <li>
              Accede al 10% de descuento del total de la boleta (sin incluir
              propina)
            </li>
            <li>Descuento aplica sólo a comida, no aplicable a bebestibles</li>
            <li>Válido sólo para consumo dentro del local</li>
            <li>No acumulable con otras promociones</li>
            <li>Descuento exclusivo presentando tu código QR</li>
          </ul>
        </Typography>
      ),
      link: (
        <a
          href="https://www.instagram.com/frontera_snack_futaleufu/"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <InstagramIcon sx={iconStyles as SxProps} />
        </a>
      ),
      schedule: (
        <div>
          <Typography variant="body2">Lunes a Sábado: 12:00 - 00:00</Typography>
          <Typography variant="body2">Domingo: Cerrado</Typography>
        </div>
      ),
      address: (
        <Typography variant="body2">
          Arutro Prat 268, Futaleufú, Los Lagos
        </Typography>
      ),
    },
    {
      name: "Escarcha Biergarten",
      logo: logoEscarchaBiergarten,
      description:
        "15% de descuento en consumo presencial presentando tu código QR",
      tag: "Cervecería/Restaurant",
      place: "Futaleufú",
      longDescription: (
        <Typography variant="body2">
          <ul>
            <li>
              Accede al 15% de descuento del total de la boleta (sin incluir
              propina)
            </li>
            <li>Válido sólo para consumo dentro del local</li>
            <li>No acumulable con otras promociones</li>
            <li>Descuento exclusivo presentando tu código QR</li>
          </ul>
        </Typography>
      ),
      link: (
        <a
          href="https://www.instagram.com/escarcha_biergarten/"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <InstagramIcon sx={iconStyles as SxProps} />
        </a>
      ),
      schedule: (
        <div>
          <Typography variant="body2">
            Martes a Sábado: 19:00 - 01:00
          </Typography>
          <Typography variant="body2">Domingo: 15:00 - 20:00</Typography>
          <Typography variant="body2">Lunes: Cerrado</Typography>
        </div>
      ),
      address: (
        <Typography variant="body2">
          Manuel Rodriguez 397, Futaleufú, Los Lagos
        </Typography>
      ),
    },
  ];

  return (
    <div className={classes.root} ref={sectionRef}>
      <Typography variant="h1">
        Disfruta de beneficios exclusivos al reservar con Easy Trip
      </Typography>
      <Grid container className={classes.container}>
        {benefits
          .map(item => ({ item, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ item }) => item)
          .map(item => (
            <Grid item xs={12} sm={6} md={4} key={item.name}>
              <Benefit
                name={item.name}
                logo={item.logo}
                description={item.description}
                tag={item.tag}
                place={item.place}
                longDescription={item.longDescription}
                link={item.link}
                schedule={item.schedule}
                address={item.address}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
}
