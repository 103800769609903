import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ComponentProps, FC, ReactNode } from "react";

import { useGetLocationsQuery } from "../hooks/api/locations";

interface Props extends Omit<ComponentProps<typeof FormControl>, "onChange"> {
  value: number | undefined;
  onChange:
    | ((event: SelectChangeEvent<number>, child: ReactNode) => void)
    | undefined;
}

const DestinationField: FC<Props> = ({ value, onChange, ...props }) => {
  const { data, isFetching } = useGetLocationsQuery({
    page_size: 100,
    page: 1,
  });
  if (isFetching) return null;
  return (
    <FormControl {...props}>
      <InputLabel id="select-destination-label" size="small">
        Destino
      </InputLabel>
      <Select
        labelId="select-destination-label"
        id="select-destination"
        label="Destino"
        onChange={onChange}
        size="small"
        value={value}
      >
        {data &&
          data.locations.map(item => (
            <MenuItem
              key={item.id}
              value={item.id}
              selected={value === item.id}
            >
              {item.location}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DestinationField;
