import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fab from "@mui/material/Fab";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";

const fabStyle = (theme: Theme) => {
  return {
    position: "fixed",
    bottom: 100,
    right: 26,
    width: 60,
    height: 60,
    zIndex: 9999,
    [theme.breakpoints.up("md")]: {
      width: 90,
      height: 90,
      bottom: 130,
    },
  };
};

const iconStyle = (theme: Theme) => {
  return {
    fontSize: 40,
    [theme.breakpoints.up("md")]: {
      fontSize: 60,
    },
  };
};

interface Props {
  scrollToTop: () => void;
}

export default function GoUpFab({ scrollToTop }: Props) {
  return (
    <Fab
      color="secondary"
      aria-label="up"
      sx={fabStyle as SxProps}
      onClick={scrollToTop}
    >
      <KeyboardArrowUpIcon sx={iconStyle as SxProps} />
    </Fab>
  );
}
