import { useCallback, useEffect, useState } from "react";

import { Activity } from "../services/types/activities";
import { Multimedia } from "../services/types/multimedia";
import { ServiceProvider } from "../services/types/serviceProviders";
import { SubCategory } from "../services/types/subCategories";
import { useLazyGetServiceProviderQuery } from "./api/serviceProviders";
import { useLazyGetSubCategoryQuery } from "./api/subCategories";
import useGetMultimedia from "./useGetMultimedia";

const useGetActivityRelatedData = (): [
  (activity: Activity) => Promise<void>,
  {
    data: {
      subCategory: SubCategory;
      multimedia: Multimedia[];
      serviceProvider: ServiceProvider;
    };
    isFetching: boolean;
  },
] => {
  const [subCategory, setSubCategory] = useState<SubCategory>({
    id: -1,
    category_id: -1,
    sub_category: "",
    activities: [],
  });
  const [serviceProvider, setServiceProvider] = useState<ServiceProvider>({
    id: -1,
    company_name: "",
    company_id: "",
    email: "",
    season_start_date: "",
    season_end_date: "",
    city: "",
    cancellation_policies: [],
  });
  const [isFetching, setIsFetching] = useState(false);

  const [fetchSubCategory, { isFetching: isFetchingSubCategory }] =
    useLazyGetSubCategoryQuery();
  const [
    getMultimedia,
    { data: multimediaData, isFetching: isFetchingMultimedia },
  ] = useGetMultimedia();
  const [fetchServiceProvider, { isFetching: isFetchingServiceProvider }] =
    useLazyGetServiceProviderQuery();

  const getActivityRelatedData = useCallback(
    async (activity: Activity) => {
      getMultimedia(
        activity.relation_multimedia_activities.map((item, index) => ({
          id: item.multimedia_id,
          index,
        })),
      );
      const subCategoryResponse = await fetchSubCategory({
        id: activity.sub_category_id,
      }).unwrap();
      setSubCategory(subCategoryResponse);
      const serviceProviderResponse = await fetchServiceProvider({
        id: activity.provider_id,
      }).unwrap();
      setServiceProvider(serviceProviderResponse);
    },
    [fetchServiceProvider, fetchSubCategory, getMultimedia],
  );

  useEffect(() => {
    setIsFetching(
      isFetchingSubCategory ||
        isFetchingMultimedia ||
        isFetchingServiceProvider,
    );
  }, [isFetchingMultimedia, isFetchingServiceProvider, isFetchingSubCategory]);

  return [
    getActivityRelatedData,
    {
      data: { subCategory, multimedia: multimediaData, serviceProvider },
      isFetching,
    },
  ];
};

export default useGetActivityRelatedData;
