import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FC, useCallback, useEffect, useState } from "react";

import {
  useGetActivitiesQuery,
  useLazyGetActivitiesQuery,
} from "../hooks/api/activities";
import { Activity } from "../services/types/activities";
import { Category } from "../services/types/categories";
import { Location } from "../services/types/locations";
import { SubCategory } from "../services/types/subCategories";
import ActivityCard from "./ActivityCard";

const PAGE_SIZE = 4;

interface Props {
  location: Location | null;
  category?: Category | null;
  subCategory?: SubCategory | null;
}

const ActivitiesSection: FC<Props> = ({ location, category, subCategory }) => {
  const [data, setData] = useState<Activity[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNext, setIsNext] = useState(false);
  const [loading, setLoading] = useState(true);

  const toSend = {
    page_size: PAGE_SIZE,
    page: 1,
    ...(location && { location: location.id }),
    ...(category && { category: category.id }),
    ...(subCategory && { subCategory: subCategory.id }),
  };

  const { data: firstPage, isFetching: isFetchingFirstPage } =
    useGetActivitiesQuery(toSend);
  const [fetchActivities] = useLazyGetActivitiesQuery();

  useEffect(() => {
    if (firstPage?.activities) setData(firstPage.activities);
    if (!isFetchingFirstPage) setLoading(false);
    setIsNext(!!firstPage?.next_page);
  }, [firstPage, isFetchingFirstPage]);

  const clickButtonHandler = useCallback(async () => {
    setLoading(true);
    const response = await fetchActivities({
      page_size: PAGE_SIZE,
      page: currentPage + 1,
      ...(location && { location: location.id }),
      ...(category && { category: category.id }),
      ...(subCategory && { subCategory: subCategory.id }),
    }).unwrap();
    setCurrentPage(prev => prev + 1);
    if (response.activities) setData(prev => [...prev, ...response.activities]);
    setIsNext(!!response?.next_page);
    setLoading(false);
  }, [category, currentPage, fetchActivities, location, subCategory]);

  return (
    <>
      <Grid container spacing={2}>
        {data.map(item => (
          <Grid item xs={12} md={6} lg={4} xl={3} key={item.id}>
            <ActivityCard activity={item} />
          </Grid>
        ))}
      </Grid>
      {loading && <Typography>Cargando...</Typography>}
      {isNext && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          <Button variant="contained" size="large" onClick={clickButtonHandler}>
            Ver más
          </Button>
        </Box>
      )}
    </>
  );
};

export default ActivitiesSection;
