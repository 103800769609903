import {
  Check as CheckIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import MUICarousel from "react-material-ui-carousel";
import { useSearchParams } from "react-router-dom";

import { useGetCategoryQuery } from "../hooks/api/categories";
import { useGetLocationQuery } from "../hooks/api/locations";
import { Activity } from "../services/types/activities";
import { Multimedia } from "../services/types/multimedia";
import { ServiceProvider } from "../services/types/serviceProviders";
import { SubCategory } from "../services/types/subCategories";
import { hoursToText, parsePrice } from "../utils";

interface BreadcrumbProps {
  location: string;
  category: string;
  subCategory: SubCategory["sub_category"];
  name: Activity["activity_name"];
}

function Breadcrumb({
  location,
  category,
  subCategory,
  name,
}: BreadcrumbProps) {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Typography variant="caption">{location}</Typography>
      <Typography variant="caption">
        {category === "Actividad" ? "Actividades" : "Hospedaje"}
      </Typography>
      <Typography variant="caption">{subCategory}</Typography>
      <Typography variant="caption">{name}</Typography>
    </Breadcrumbs>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
  activity: Activity;
  subCategory: SubCategory;
  multimedia: Multimedia[];
  serviceProvider: ServiceProvider;
  smallScreen: boolean;
  openImage: boolean;
  onCloseImage: () => void;
  onOpenImage: () => void;
}
export default function CardDialog({
  open,
  onClose,
  activity: {
    id: activity_id,
    activity_name,
    duration_hours,
    schedule,
    call_to_action,
    description,
    sale_price,
    price_per,
    location_id,
    includes,
    recommendations,
  },
  subCategory,
  multimedia,
  serviceProvider,
  smallScreen,
  openImage,
  onCloseImage,
  onOpenImage,
}: Props) {
  const { data: location, isFetching: isFetchingLocation } =
    useGetLocationQuery({ id: location_id });

  const { data: category, isFetching: isFetchingCategory } =
    useGetCategoryQuery({ id: subCategory.category_id });

  const [currentOpenImage, setCurrentOpenImage] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const callToActionList = useMemo(
    () => call_to_action?.split("\n"),
    [call_to_action],
  );

  const descriptionList = useMemo(
    () => description?.split("\n"),
    [description],
  );

  const includesJson = useMemo(() => JSON.parse(includes || "{}"), [includes]);

  const recommendationsList = useMemo(
    () => recommendations?.split("\n"),
    [recommendations],
  );

  const cancellationPolicyList = useMemo(
    () =>
      serviceProvider.cancellation_policies.length
        ? serviceProvider.cancellation_policies[0].description.split("\n")
        : [],
    [serviceProvider.cancellation_policies],
  );

  const whatsappLink = useMemo(
    () =>
      `https://wa.me/56931778303?text=Hola,%20me%20interesa%20la%20siguiente%20actividad%20https://easytrip.cl?activity=${activity_id}-${activity_name.replaceAll(
        " ",
        "%2B",
      )}`,
    [activity_id, activity_name],
  );

  useEffect(() => {
    if (open) {
      searchParams.set("activity", `${activity_id}-${activity_name}`);
      setSearchParams(searchParams);
    }
  }, [activity_id, activity_name, open, searchParams, setSearchParams]);

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Box sx={{ px: { xs: 2, md: 16 }, py: 6 }}>
        {openImage ? (
          <IconButton
            aria-label="close"
            onClick={onCloseImage}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon fontSize="large" sx={{ color: "common.black" }} />
          </IconButton>
        ) : (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", left: { xs: 5, md: 115 }, top: 2 }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        )}
        <Dialog open={openImage} onClose={onCloseImage}>
          <img src={currentOpenImage} alt="representación de la actividad" />
        </Dialog>
        {smallScreen ? (
          <>
            <MUICarousel sx={{ height: 380 }}>
              {multimedia.map(({ id, link }) => (
                <Card
                  key={id}
                  sx={{
                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={() => {
                    setCurrentOpenImage(link || "");
                    onOpenImage();
                  }}
                >
                  <CardMedia
                    image={link || undefined}
                    title="representación de la actividad"
                    style={{
                      height: 0,
                      paddingTop: "80%",
                    }}
                  />
                </Card>
              ))}
            </MUICarousel>
            {!isFetchingLocation &&
              !isFetchingCategory &&
              location &&
              category && (
                <Breadcrumb
                  location={location.location}
                  category={category.category}
                  subCategory={subCategory.sub_category}
                  name={activity_name}
                />
              )}
            <Typography variant="h3">{activity_name}</Typography>
            <a href={whatsappLink} target="_blank" rel="noreferrer">
              <Button
                variant="contained"
                size="large"
                color="info"
                sx={{ marginTop: 3, boxShadow: 10 }}
              >
                <Typography fontWeight="bold">QUIERO RESERVAR</Typography>
              </Button>
            </a>
            <Typography color="text.secondary" sx={{ my: 2 }}>
              {sale_price ? parsePrice(sale_price, price_per || "") : ""}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4} lg={3}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1">Duración</Typography>
                  <Typography variant="subtitle2">
                    {hoursToText(duration_hours)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1">Horario</Typography>
                  <Typography variant="subtitle2">{schedule}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ my: 2 }}>
              {callToActionList?.map(item => (
                <Typography key={item}>{item}</Typography>
              ))}
            </Box>
          </>
        ) : (
          <>
            {!isFetchingLocation &&
              !isFetchingCategory &&
              location &&
              category && (
                <Breadcrumb
                  location={location.location}
                  category={category.category}
                  subCategory={subCategory.sub_category}
                  name={activity_name}
                />
              )}
            <Typography variant="h3">{activity_name}</Typography>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <MUICarousel sx={{ paddingTop: 5 }}>
                  {multimedia.map(({ id, link }) => (
                    <Card
                      key={id}
                      sx={{
                        "&:hover": { cursor: "pointer" },
                      }}
                      onClick={() => {
                        setCurrentOpenImage(link || "");
                        onOpenImage();
                      }}
                    >
                      <CardMedia
                        image={link || undefined}
                        title="representación de la actividad"
                        style={{
                          paddingTop: "70%",
                        }}
                      />
                    </Card>
                  ))}
                </MUICarousel>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ marginTop: 4, marginBottom: 8 }}>
                  {callToActionList?.map(item => (
                    <Typography key={item}>{item}</Typography>
                  ))}
                </Box>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="body1" fontWeight="bold">
                      Precio
                    </Typography>
                    <Typography color="text.secondary" gutterBottom>
                      {sale_price
                        ? parsePrice(sale_price, price_per || "")
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <a href={whatsappLink} target="_blank" rel="noreferrer">
                      <Button
                        variant="contained"
                        size="large"
                        color="info"
                        sx={{ boxShadow: 10 }}
                      >
                        <Typography fontWeight="bold">
                          QUIERO RESERVAR
                        </Typography>
                      </Button>
                    </a>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: 8, marginBottom: 4 }}
                >
                  <Grid item xs={6} md={4}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="subtitle1">Duración</Typography>
                      <Typography variant="body2">
                        {hoursToText(duration_hours)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="subtitle1">Horario</Typography>
                      <Typography variant="body2">{schedule}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        <Accordion>
          <AccordionSummary
            sx={{ backgroundColor: "primary.main" }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary.contrastText">Descripción</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {descriptionList?.map(item => (
              <>
                {item === "" && <br />}
                <Typography key={item} variant="body2">
                  {item}
                </Typography>
              </>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            sx={{ backgroundColor: "primary.main" }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary.contrastText">Incluye</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {Object.keys(includesJson.español).map(item => (
              <Box
                key={item}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {includesJson.español[item] === "TRUE" ? (
                  <CheckIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
                <Typography>{item}</Typography>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            sx={{ backgroundColor: "primary.main" }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary.contrastText">
              Recomendaciones
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {recommendationsList?.map(item => (
              <Typography key={item}>{item}</Typography>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            sx={{ backgroundColor: "primary.main" }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography color="primary.contrastText">
              Política de cancelación
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {cancellationPolicyList?.map(item => (
              <Typography key={item}>{item}</Typography>
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Dialog>
  );
}
