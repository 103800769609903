import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 80,
      paddingBottom: 80,
      [theme.breakpoints.up("md")]: {
        paddingLeft: 100,
        paddingRight: 100,
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: 300,
        paddingRight: 300,
      },
      backgroundColor: "white",
    },
    stack: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iframe: {
      border: "none",
      overflowX: "hidden",
      overflowY: "scroll",
    },
  }),
);

export default useStyles;
