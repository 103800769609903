import { alpha, Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

import background from "../assets/images/futaleufu1.jpg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      backgroundImage: `url(${background})`,
      backgroundColor: alpha(theme.palette.grey[900], 0.6),
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundBlendMode: "darken",
      minHeight: "100vh",
      paddingTop: 80,
    },
  }),
);

export default useStyles;
