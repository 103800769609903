import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Theme, useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import React, { useEffect, useState } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { useNavigate } from "react-router-dom";

import logo1 from "../assets/images/logos/LOGO BLANCO-01.svg";
import logo2 from "../assets/images/logos/LOGOTIPO EASY TRIP DEGRADE-01.svg";
import useStyles from "../styles/NavbarStyles";
import GoUpFab from "./GoUpFab";

interface ElevationScrollProps {
  children: React.ReactElement;
}

function ElevationScroll({ children }: ElevationScrollProps) {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sections?: { text: string; ref: React.MutableRefObject<any> }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scrollToSection: (ref: React.MutableRefObject<any>) => void;
  externalSections?: { text: string; link: string }[];
}

export default function NavBar({
  sections = [],
  scrollToSection,
  externalSections = [],
}: Props) {
  const classes = useStyles();

  const navigate = useNavigate();

  const theme: Theme = useTheme();
  const isMobile: boolean = useMediaQuery(theme.breakpoints.down("md"));

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [atTop, setAtTop] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = (
    <List>
      {sections.map(item => (
        <ListItem
          button
          key={item.text}
          onClick={() => {
            setDrawerOpen(false);
            scrollToSection(item.ref);
          }}
        >
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
      {externalSections.map(item => (
        <ListItem button key={item.text} onClick={() => navigate(item.link)}>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        setAtTop(true);
      } else if (atTop) {
        setAtTop(false);
      }
    };
  });

  return (
    <>
      <CssBaseline />
      <ElevationScroll>
        <AppBar color={atTop ? "primary" : "inherit"}>
          {!atTop && <GoUpFab scrollToTop={scrollToTop} />}
          <Toolbar>
            <div className={classes.brand}>
              {atTop ? (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <img
                  src={logo1}
                  alt={logo1}
                  className={classes.logo}
                  onClick={() => navigate("/")}
                />
              ) : (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
                <img
                  src={logo2}
                  alt={logo2}
                  className={classes.logo}
                  onClick={() => navigate("/")}
                />
              )}
            </div>
            {isMobile ? (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                  classes={{ paper: classes.drawer }}
                >
                  {list}
                </Drawer>
              </>
            ) : (
              sections
                .map(item => (
                  <Button
                    key={item.text}
                    color="inherit"
                    className={classes.button}
                    onClick={() => scrollToSection(item.ref)}
                  >
                    <Typography
                      variant="h6"
                      color={atTop ? "common.white" : "inherit"}
                    >
                      {item.text}
                    </Typography>
                  </Button>
                ))
                .concat(
                  externalSections.map(item => (
                    <Button
                      key={item.text}
                      color="inherit"
                      className={classes.button}
                      onClick={() => navigate(item.link)}
                    >
                      <Typography
                        variant="h6"
                        color={atTop ? "common.white" : "inherit"}
                      >
                        {item.text}
                      </Typography>
                    </Button>
                  )),
                )
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    </>
  );
}
