import { createApi } from "@reduxjs/toolkit/dist/query/react";

import SubCategoriesService from "../../services/subCategories";
import {
  GetSubCategoryArgs,
  GetSubCategoryResponse,
} from "../../services/types/subCategories";
import { apiBaseQuery } from "../utils/store";

const subCategoriesApi = createApi({
  reducerPath: "subCategoriesApi",
  baseQuery: apiBaseQuery(),
  tagTypes: ["SubCategory"],
  endpoints: builder => ({
    getSubCategory: builder.query<GetSubCategoryResponse, GetSubCategoryArgs>({
      query: args => ({ func: SubCategoriesService.getSubCategory, args }),
      providesTags: (_result, _error, arg) => [
        { type: "SubCategory", id: arg.id },
      ],
    }),
  }),
});

export default subCategoriesApi;
