import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 80,
      paddingBottom: 80,
      backgroundColor: theme.palette.secondary.dark,
    },
    container: {
      maxWidth: 800,
      margin: "auto",
      padding: 40,
    },
    button: {
      width: 400,
    },
  }),
);

export default useStyles;
