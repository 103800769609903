import { createApi } from "@reduxjs/toolkit/dist/query/react";

import ServiceProvidersService from "../../services/serviceProviders";
import {
  GetServiceProviderArgs,
  GetServiceProviderResponse,
} from "../../services/types/serviceProviders";
import { apiBaseQuery } from "../utils/store";

const serviceProvidersApi = createApi({
  reducerPath: "serviceProvidersApi",
  baseQuery: apiBaseQuery(),
  tagTypes: ["ServiceProvider"],
  endpoints: builder => ({
    getServiceProvider: builder.query<
      GetServiceProviderResponse,
      GetServiceProviderArgs
    >({
      query: args => ({
        func: ServiceProvidersService.getServiceProvider,
        args,
      }),
      providesTags: (_result, _error, arg) => [
        { type: "ServiceProvider", id: arg.id },
      ],
    }),
  }),
});

export default serviceProvidersApi;
