import logo from "../assets/images/logos/ISOTIPO EASY TRIP BLANCO.svg";
import useStyles from "../styles/FooterStyles";

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={logo} alt={logo} className={classes.logo} />
    </div>
  );
}
