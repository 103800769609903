import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      paddingTop: 100,
    },
    container: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingBottom: 80,
      maxWidth: 1000,
      margin: "auto",
    },
    sectionContainer: {
      paddingTop: 150,
    },
    subSection: {
      paddingTop: 130,
    },
    buttonContainer: {
      position: "sticky",
      top: 100,
      bottom: 200,
      zIndex: 9999,
    },
    title: {
      paddingTop: 100,
    },
  }),
);

export default useStyles;
