import axios, { AxiosResponse } from "axios";

import {
  GetServiceProviderArgs,
  GetServiceProviderResponse,
} from "./types/serviceProviders";

class ServiceProvidersService {
  static getServiceProvider = async (
    args: GetServiceProviderArgs,
  ): Promise<Partial<AxiosResponse<GetServiceProviderResponse>>> =>
    axios.get(`https://easytrip.cl/api/service-providers/${args.id}`);
}

export default ServiceProvidersService;
