import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ComponentProps, FC, ReactNode } from "react";

import { Category } from "../services/types/categories";

interface Props extends Omit<ComponentProps<typeof FormControl>, "onChange"> {
  value: number | undefined;
  onChange:
    | ((event: SelectChangeEvent<number>, child: ReactNode) => void)
    | undefined;
  categories: Category[] | undefined;
  isFetching: boolean;
}

const CategoryField: FC<Props> = ({
  value,
  onChange,
  categories,
  isFetching,
  ...props
}) => {
  if (isFetching) return null;
  return (
    <FormControl {...props}>
      <InputLabel id="select-looking-for-label" size="small">
        Estoy buscando
      </InputLabel>
      <Select
        labelId="select-looking-for-label"
        id="select-looking-for"
        value={value}
        label="Estoy buscando"
        onChange={onChange}
        size="small"
      >
        {categories &&
          categories.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.category}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default CategoryField;
