import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 80,
    },
    infoContainer: {
      padding: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    formContainer: {
      backgroundColor: "white",
      padding: 30,
      borderRadius: 10,
      width: "100%",
    },
    linksContainer: {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
    },
  }),
);

export default useStyles;
