import axios, { AxiosResponse } from "axios";

import {
  GetLocationArgs,
  GetLocationResponse,
  GetLocationsArgs,
  GetLocationsResponse,
} from "./types/locations";

class LocationsService {
  static getLocation = async (
    args: GetLocationArgs,
  ): Promise<Partial<AxiosResponse<GetLocationResponse>>> =>
    axios.get(`https://easytrip.cl/api/locations/${args.id}`);

  static getLocations = async (
    args: GetLocationsArgs,
  ): Promise<Partial<AxiosResponse<GetLocationsResponse>>> =>
    axios.get(
      `https://easytrip.cl/api/locations/?page_size=${args.page_size}&page=${args.page}`,
    );
}

export default LocationsService;
