import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      marginTop: 30,
      display: "flex",
      justifyContent: "center",
    },
    chip: {
      marginLeft: 10,
      marginTop: 10,
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }),
);

export default useStyles;
