import { createApi } from "@reduxjs/toolkit/dist/query/react";

import MultimediaService from "../../services/multimedia";
import {
  GetMultimediaArgs,
  GetMultimediaResponse,
} from "../../services/types/multimedia";
import { apiBaseQuery } from "../utils/store";

const multimediaApi = createApi({
  reducerPath: "multimediaApi",
  baseQuery: apiBaseQuery(),
  tagTypes: ["Multimedia"],
  endpoints: builder => ({
    getMultimedia: builder.query<GetMultimediaResponse, GetMultimediaArgs>({
      query: args => ({ func: MultimediaService.getMultimedia, args }),
      providesTags: (_result, _error, arg) => [
        { type: "Multimedia", id: arg.id },
      ],
    }),
  }),
});

export default multimediaApi;
