import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 80,
      paddingBottom: 80,
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    container: {
      maxWidth: 800,
    },
    link: {
      display: "flex",
      justifyContent: "center",
    },
  }),
);

export default useStyles;
