import { esES } from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";
import { createBreakpoints } from "@mui/system";

const themeSpacing = 8;

const primary = {
  main: "#70a84a",
  dark: "#2a5927",
  contrastText: "#fff",
};

const secondary = {
  main: "#2f9191",
  dark: "#0f6f8c",
  contrastText: "#fff",
};

const info = {
  main: "#edbe17",
};

const grey = {
  10: "#F2F3F6",
  50: "#ECEFF1",
  100: "#CFD8DC",
  200: "#B0BEC5",
  300: "#90A4AE",
  400: "#78909C",
  500: "#607D8B",
  600: "#546E7A",
  700: "#455A64",
  800: "#37474F",
  900: "#263238",
};

const breakpoints = createBreakpoints({});

const theme = createTheme(
  {
    palette: {
      primary,
      secondary,
      info,
      grey,
    },
    spacing: themeSpacing,
    typography: {
      fontFamily: "'Quicksand', sans-serif",
      allVariants: {
        color: grey[900],
      },
      h1: {
        fontSize: "2.25rem",
        fontWeight: "bold",
        color: grey[800],
        [breakpoints.up("md")]: {
          fontSize: "3rem",
        },
      },
      h2: {
        fontSize: "1.8rem",
        fontWeight: 800,
        color: grey[800],
        [breakpoints.up("md")]: {
          fontSize: "1.9rem",
        },
      },
      h3: {
        fontSize: "1.5rem",
        fontWeight: 800,
        color: grey[800],
        [breakpoints.up("md")]: {
          fontSize: "1.75rem",
        },
      },
      subtitle1: {
        fontSize: "1.125rem",
        color: grey[500],
        [breakpoints.up("md")]: {
          fontSize: "1.25rem",
        },
      },
      body1: {
        fontSize: "1.3rem",
        [breakpoints.up("md")]: {
          fontSize: "1.4rem",
        },
      },
      body2: {
        fontSize: "1.125rem",
      },
      button: {
        textTransform: "none",
      },
    },
  },
  esES,
);

export default theme;
