/* eslint-disable import/no-extraneous-dependencies */
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/react";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { Dispatch } from "redux";
import { serializeError } from "serialize-error";

import { ApiData, ApiError } from "../../services/types/shared";
import { errorMessage } from "../shared";

type NoArgsFunc<Data> = () => Promise<Data>;
type ArgsFunc<Data, Args> = (args: Args) => Promise<Data>;

const handleError = (error: Error, dispatch: Dispatch) => {
  let serializedError = serializeError(error);
  serializedError = _.omit(serializedError, "config");
  dispatch(errorMessage({ error: serializedError }));
};

export const apiEndpoint = async <Data extends AxiosResponse<Data>, Args>(
  func: NoArgsFunc<Data> | ArgsFunc<Data, Args>,
  api: BaseQueryApi,
  args?: Args,
): Promise<ApiData<Data> | ApiData<null> | { error: ApiError }> => {
  try {
    let res;
    if (args) {
      res = await func(args);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      res = await func();
    }
    return { data: res.data };
  } catch (error) {
    const err = error as AxiosError;
    handleError(error as Error, api.dispatch);
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};

export const apiBaseQuery =
  <Data extends AxiosResponse<Data>, Args>(): BaseQueryFn<
    { func: NoArgsFunc<Data> | ArgsFunc<Data, Args>; args?: Args },
    unknown,
    unknown
  > =>
  async ({ func, args }, api) => {
    return apiEndpoint(func, api, args);
  };
