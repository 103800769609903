import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { FC, useCallback, useEffect, useState } from "react";

import { useGetCategoriesQuery } from "../hooks/api/categories";
import { Category } from "../services/types/categories";
import { Location } from "../services/types/locations";
import { SubCategory } from "../services/types/subCategories";
import CategoryField from "./CategoryField";
import DestinationField from "./DestinationField";
import SubCategoryField from "./SubCategoryField";

interface Bla {
  location: number | null;
  category: number | null;
  subCategory: number | null;
}

interface Props {
  location: Location | null;
  category?: Category | null;
  subCategory?: SubCategory | null;
  onChange: (data: Bla) => void;
}

const SearchBox: FC<Props> = ({
  location,
  category,
  subCategory,
  onChange,
}) => {
  const [destination, setDestination] = useState<number>();
  const [lookingFor, setLookingFor] = useState<number>();
  const [subCat, setSubCat] = useState<number>();
  const [subCatList, setSubCatList] = useState<SubCategory[]>();

  const { data: categoriesData, isFetching: isFetchingCategories } =
    useGetCategoriesQuery({
      page_size: 100,
      page: 1,
    });

  useEffect(() => {
    if (location) setDestination(location.id);
    if (category) {
      setLookingFor(category.id);
      setSubCatList(category.sub_categories);
    }
    if (subCategory) setSubCat(subCategory.id);
  }, [category, location, subCategory]);

  const changeDestination = useCallback(
    (value: number) => {
      setDestination(value);
      onChange({
        location: value || null,
        category: lookingFor || null,
        subCategory: subCat || null,
      });
    },
    [lookingFor, onChange, subCat],
  );

  const changeLookingFor = useCallback(
    (value: number) => {
      setLookingFor(value);
      onChange({
        location: destination || null,
        category: value || null,
        subCategory: subCat || null,
      });
      const selectedCategoryList = categoriesData?.categories.filter(
        item => item.id === value,
      );
      const selectedCategory =
        selectedCategoryList && selectedCategoryList.length > 0
          ? selectedCategoryList[0]
          : null;
      if (selectedCategory) {
        setSubCatList(selectedCategory.sub_categories);
        setSubCat(undefined);
      }
    },
    [categoriesData?.categories, destination, onChange, subCat],
  );

  const changeSubCat = useCallback(
    (value: number) => {
      setSubCat(value);
      onChange({
        location: destination || null,
        category: lookingFor || null,
        subCategory: value || null,
      });
    },
    [destination, lookingFor, onChange],
  );

  return (
    <Box sx={{ p: 2 }}>
      <Card>
        <CardContent>
          <Typography variant="h5">¡Encuentra lo que buscas!</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: window.innerWidth < 900 ? "column" : "row",
              justifyContent: "center",
            }}
          >
            <DestinationField
              fullWidth={window.innerWidth >= 900}
              sx={{ margin: 2 }}
              value={destination}
              onChange={e => changeDestination(Number(e.target.value))}
            />
            <CategoryField
              fullWidth={window.innerWidth >= 900}
              sx={{ margin: 2 }}
              value={lookingFor}
              onChange={e => changeLookingFor(Number(e.target.value))}
              categories={categoriesData?.categories}
              isFetching={isFetchingCategories}
            />
            <SubCategoryField
              fullWidth={window.innerWidth >= 900}
              sx={{ margin: 2 }}
              value={subCat}
              onChange={e => changeSubCat(Number(e.target.value))}
              subCategories={subCatList}
              isFetching={isFetchingCategories}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SearchBox;
