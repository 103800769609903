import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";

import useStyles from "../styles/HowToArriveStyles";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sectionRef: React.MutableRefObject<any>;
  textStyles: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subSectionRefs: React.MutableRefObject<any>[];
}

export default function HowToArriveByCarSection({
  sectionRef,
  textStyles,
  subSectionRefs,
}: Props) {
  const classes = useStyles();

  const boldText = {
    ...textStyles,
    fontWeight: "bold",
  };

  return (
    <div className={classes.sectionContainer} ref={sectionRef}>
      <Typography variant="h1" component="h2">
        Si vienes en vehículo
      </Typography>
      <Typography
        variant="h3"
        component="h3"
        className={classes.subSection}
        ref={subSectionRefs[0]}
      >
        Vía marítima
      </Typography>
      <Typography variant="body2" sx={textStyles as SxProps}>
        <Typography variant="body2" component="span" sx={boldText as SxProps}>
          Opción 1:{" "}
        </Typography>
        Primero debes llegar en tu vehículo hasta Puerto Montt, en donde tienes
        que tomar una barcaza hasta Chaitén. Tiene una duración aproximada entre
        9 y 10 hrs. Para tomar esta opción visita{" "}
        <a
          href="https://ventas.navieraustral.cl/"
          target="_blank"
          rel="noreferrer"
        >
          https://ventas.navieraustral.cl/
        </a>
        . Es importante que consideres reservar tu cupo para esta barcaza con al
        menos 1 SEMANA DE ANTICIPACIÓN, dado que la demanda es alta y solo se
        realiza 1 viaje por día.
      </Typography>
      <Typography variant="body2" sx={textStyles as SxProps}>
        <Typography variant="body2" component="span" sx={boldText as SxProps}>
          Opción 2:{" "}
        </Typography>
        Deberás llegar hasta Hornopirén (puedes llegar desde Puerto Montt o
        Ensenada) y tomar la ruta bimodal. El viaje de Hornopirén – Chaitén
        tiene una duración aproximada entre 5 y 6 horas. Para tomar esta opción
        visita{" "}
        <a
          href="https://www.barcazas.cl/barcazas/comprar-pasaje-hornopiren-caleta-gonzalo/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.barcazas.cl/barcazas/comprar-pasaje-hornopiren-caleta-gonzalo/
        </a>
        . Es importante que consideres reservar tu cupo para esta barcaza con al
        menos 1 SEMANA DE ANTICIPACIÓN, dado que la demanda es alta y sólo se
        realizan hasta 2 viajes por día.
      </Typography>
      <Typography variant="body2" sx={textStyles as SxProps}>
        Cualquiera de las 2 opciones mencionadas te dejará en Chaitén, en dónde
        debes seguir tu rumbo por la ruta 7 hasta Villa Santa Lucía. Aquí
        deberás tomar el desvío hasta Futaleufú, tomando la ruta 235. Te
        recomendamos apoyarte en aplicaciones como Google Maps o Waze. La
        duración de Chaitén - Futaleufú es de aproximadamente 3 horas.
      </Typography>
      <Typography
        variant="h3"
        component="h3"
        className={classes.subSection}
        ref={subSectionRefs[1]}
      >
        Vía terrestre
      </Typography>
      <Typography variant="body2" color="info.main" sx={boldText as SxProps}>
        [Dadas las restricciones sanitarias, esta opción no está habilitada dado
        que los pasos fronterizos necesarios están cerrados]
      </Typography>
      <Typography variant="body2" sx={textStyles as SxProps}>
        Esta es la opción internacional. Desde Osorno debes salir hacia
        Argentina, pasar por Bariloche, El Bolsón y Esquel para luego ingresar a
        Chile por el paso fronterizo &quot;El Límite&quot;. Desde aquí debes
        seguir 10 km por la ruta y llegarás al centro de Futaleufú. Recuerda
        revisar los papeles necesarios para poder cruzar de un país al otro,
        esta información la encuentras en{" "}
        <a
          href="https://www.chileatiende.gob.cl/fichas/106-solicitar-permiso-para-el-ingreso-o-salida-temporal-de-un-vehiculo-particular-desde-y-hacia-argentina"
          target="_blank"
          rel="noreferrer"
        >
          https://www.barcazas.cl/barcazas/comprar-pasaje-hornopiren-caleta-gonzalo/
        </a>
        .
      </Typography>
    </div>
  );
}
