import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ComponentProps, FC, ReactNode } from "react";

import { SubCategory } from "../services/types/subCategories";

interface Props extends Omit<ComponentProps<typeof FormControl>, "onChange"> {
  value: number | undefined;
  onChange:
    | ((event: SelectChangeEvent<number>, child: ReactNode) => void)
    | undefined;
  subCategories: SubCategory[] | undefined;
  isFetching: boolean;
}

const SubCategoryField: FC<Props> = ({
  value,
  onChange,
  subCategories,
  isFetching,
  ...props
}) => {
  if (isFetching) return null;
  return (
    <FormControl {...props}>
      <InputLabel id="select-sub-category-label" size="small">
        De tipo
      </InputLabel>
      <Select
        labelId="select-sub-category-label"
        id="select-sub-category"
        value={value}
        label="De tipo"
        onChange={onChange}
        size="small"
      >
        {subCategories &&
          subCategories.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.sub_category}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SubCategoryField;
