import { createApi } from "@reduxjs/toolkit/dist/query/react";

import CategoriesService from "../../services/categories";
import {
  GetCategoriesArgs,
  GetCategoriesResponse,
  GetCategoryArgs,
  GetCategoryResponse,
} from "../../services/types/categories";
import { apiBaseQuery } from "../utils/store";

const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: apiBaseQuery(),
  tagTypes: ["Category", "Categories"],
  endpoints: builder => ({
    getCategory: builder.query<GetCategoryResponse, GetCategoryArgs>({
      query: args => ({ func: CategoriesService.getCategory, args }),
      providesTags: (_result, _error, arg) => [
        { type: "Category", id: arg.id },
      ],
    }),
    getCategories: builder.query<GetCategoriesResponse, GetCategoriesArgs>({
      query: args => ({ func: CategoriesService.getCategories, args }),
      providesTags: (_result, _error, arg) => [
        { type: "Categories", id: `${arg.page_size}-${arg.page}` },
      ],
    }),
  }),
});

export default categoriesApi;
