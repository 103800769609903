import { useCallback, useState } from "react";

import { Multimedia } from "../services/types/multimedia";
import { useLazyGetMultimediaQuery } from "./api/multimedia";

const useGetMultimedia = (): [
  (multimediaList: { id: Multimedia["id"]; index: number }[]) => Promise<void>,
  { data: Multimedia[]; isFetching: boolean },
] => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<Multimedia[]>([]);

  const [fetchMultimedia] = useLazyGetMultimediaQuery();

  const getMultimedia = useCallback(
    async (multimediaList: { id: Multimedia["id"]; index: number }[]) => {
      setIsFetching(true);
      const newList: { mult: Multimedia; index: number }[] = [];
      const promises = multimediaList.map(async item => {
        const response = await fetchMultimedia({ id: item.id }).unwrap();
        newList.push({ mult: response, index: item.index });
        return response;
      });
      await promises.reduce((m, o) => m.then(() => o));
      newList.sort((a, b) => a.index - b.index);
      setData(newList.map(item => item.mult));
      setIsFetching(false);
    },
    [fetchMultimedia],
  );

  return [getMultimedia, { data, isFetching }];
};

export default useGetMultimedia;
