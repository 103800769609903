import axios, { AxiosResponse } from "axios";

import { GetMultimediaArgs, GetMultimediaResponse } from "./types/multimedia";

class MultimediaService {
  static getMultimedia = async (
    args: GetMultimediaArgs,
  ): Promise<Partial<AxiosResponse<GetMultimediaResponse>>> =>
    axios.get(`https://easytrip.cl/api/multimedia/${args.id}`);
}

export default MultimediaService;
