import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/system";

import useStyles from "../styles/HowToArriveStyles";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sectionRef: React.MutableRefObject<any>;
  textStyles: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subSectionRefs: React.MutableRefObject<any>[];
}

export default function HowToArriveByFootSection({
  sectionRef,
  textStyles,
  subSectionRefs,
}: Props) {
  const classes = useStyles();

  const boldText = {
    ...textStyles,
    fontWeight: "bold",
  };

  return (
    <div className={classes.sectionContainer} ref={sectionRef}>
      <Typography variant="h1" component="h2">
        Si vienes a pie
      </Typography>
      <Typography
        variant="h3"
        component="h3"
        className={classes.subSection}
        ref={subSectionRefs[0]}
      >
        Vía marítima
      </Typography>
      <Typography variant="body2" sx={textStyles as SxProps}>
        <Typography variant="body2" component="span" sx={boldText as SxProps}>
          Opción 1:{" "}
        </Typography>
        Comprar tu pasaje para la barcaza que conecta a Puerto Montt con
        Chaitén. Tiene una duración aproximada entre 9 y 10 hrs. Para tomar esta
        opción visita{" "}
        <a
          href="https://ventas.navieraustral.cl/"
          target="_blank"
          rel="noreferrer"
        >
          https://ventas.navieraustral.cl/
        </a>{" "}
        Es importante que consideres reservar tu cupo para esta barcaza con al
        menos 1 SEMANA DE ANTICIPACIÓN, dado que la demanda es alta y sólo se
        realiza 1 viaje por día.
      </Typography>
      <Typography variant="body2" sx={textStyles as SxProps}>
        <Typography variant="body2" component="span" sx={boldText as SxProps}>
          Opción 2:{" "}
        </Typography>
        Puedes tomar un bus en Puerto Montt que realizará la ruta bimodal. El
        bus llegará a Hornopirén en dónde tomará la barcaza hasta Chaitén. Todo
        este recorrido toma un tiempo aproximado de 12 horas. Para más
        información de bus visita{" "}
        <a href="http://www.kemelbus.cl/" target="_blank" rel="noreferrer">
          http://www.kemelbus.cl/
        </a>
      </Typography>
      <Typography
        variant="h3"
        component="h3"
        className={classes.subSection}
        ref={subSectionRefs[1]}
      >
        Vía aérea
      </Typography>
      <Typography variant="body2" sx={textStyles as SxProps}>
        Desde Puerto Montt puedes tomar una avioneta que te dejará en Chaitén.
        El tiempo de viaje es aproximadamente entre 30 y 40 minutos. Puedes
        revisar los siguientes sitios para hacer la compra de tus pasajes:
      </Typography>
      <List>
        <ListItem>
          <Stack>
            <Typography variant="body2">
              -{" "}
              <a
                href="https://www.pewenchile.com/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.pewenchile.com/
              </a>
            </Typography>
            <List>
              <ListItem>
                <Typography variant="body2">
                  Correo:{" "}
                  <a href="mailto:ventas@pewenchile.com">
                    ventas@pewenchile.com
                  </a>
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">Teléfono: 652224000</Typography>
              </ListItem>
            </List>
          </Stack>
        </ListItem>
        <ListItem>
          <Stack>
            <Typography variant="body2">
              -{" "}
              <a
                href="https://www.aerocord.cl/movil/inicio.html"
                target="_blank"
                rel="noreferrer"
              >
                https://www.aerocord.cl/movil/inicio.html
              </a>
            </Typography>
            <List>
              <ListItem>
                <Typography variant="body2">
                  Correo:{" "}
                  <a href="mailto:aperez@aerocord.cl">aperez@aerocord.cl</a>
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">Teléfono: 76694663</Typography>
              </ListItem>
            </List>
          </Stack>
        </ListItem>
      </List>
      <Typography variant="body2" sx={textStyles as SxProps}>
        Considera que la información de estos últimos sitios puede estar
        desactualizada, por lo que te recomendamos contactarlos directamente, la
        información de contacto también sale en sus páginas web.
      </Typography>
      <Typography variant="body2" sx={textStyles as SxProps}>
        Cualquiera de las vías que tomes te dejarán en Chaitén. Desde aquí
        deberás tomar un bus que te llevará directamente a Futaleufú en un viaje
        que dura aproximadamente 3 horas. Este bus sale de Chaitén 2 veces al
        día de lunes a domingo, para saber los horarios debes llamar al
        (65)2721360.
      </Typography>
    </div>
  );
}
