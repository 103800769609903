import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "white",
      padding: 10,
      borderRadius: 5,
      width: "100%",
    },
    fieldContainer: {
      padding: 10,
    },
    textArea: {
      width: "100%",
    },
  }),
);

export default useStyles;
