import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import { forwardRef, useState } from "react";

import useStyles from "../styles/BenefitStyles";

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const MyDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  name: string;
  logo: string;
  description: string;
  tag: string;
  place: string;
  longDescription: React.ReactElement;
  link: React.ReactElement;
  schedule: React.ReactElement;
  address: React.ReactElement;
}

export default function Benefit({
  name,
  logo,
  description,
  tag,
  place,
  longDescription,
  link,
  schedule,
  address,
}: Props) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Card
        onClick={handleOpen}
        sx={{
          width: 240,
          height: 400,
          boxShadow: 5,
          borderRadius: 5,
          cursor: "pointer",
        }}
      >
        <Chip label={tag} color="secondary" className={classes.chip} />
        <CardMedia
          component="img"
          height="200"
          image={logo}
          alt={`logo ${name}`}
        />
        <CardContent>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          scroll="paper"
          onClose={handleClose}
          aria-describedby="deescripcion-beneficio"
        >
          <MyDialogTitle id={`beneficio-${name}`} onClose={handleClose}>
            {`${name}, ${place}`}
          </MyDialogTitle>
          <DialogContent className={classes.dialogContent}>
            <img src={logo} alt={`logo ${name}`} />
            <Grid container spacing={6}>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" gutterBottom>
                  Descripción
                </Typography>
                {longDescription}
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  <Typography variant="h3" gutterBottom>
                    Horarios
                    {schedule}
                  </Typography>
                  <Typography variant="h3" gutterBottom>
                    Dirección
                    {address}
                  </Typography>
                  {link}
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </div>
  );
}
