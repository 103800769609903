import axios, { AxiosResponse } from "axios";

import {
  GetSubCategoryArgs,
  GetSubCategoryResponse,
} from "./types/subCategories";

class SubCategoriesService {
  static getSubCategory = async (
    args: GetSubCategoryArgs,
  ): Promise<Partial<AxiosResponse<GetSubCategoryResponse>>> =>
    axios.get(`https://easytrip.cl/api/sub-categories/${args.id}`);
}

export default SubCategoriesService;
