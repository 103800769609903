import { createApi } from "@reduxjs/toolkit/dist/query/react";

import ActivitiesService from "../../services/activities";
import {
  GetActivitiesArgs,
  GetActivitiesResponse,
  GetActivityArg,
  GetActivityResponse,
} from "../../services/types/activities";
import { apiBaseQuery } from "../utils/store";

const activitiesApi = createApi({
  reducerPath: "activitiesApi",
  baseQuery: apiBaseQuery(),
  tagTypes: ["Activity", "Activities"],
  endpoints: builder => ({
    getActivity: builder.query<GetActivityResponse, GetActivityArg>({
      query: args => ({ func: ActivitiesService.getActivity, args }),
      providesTags: (_result, _error, arg) => [
        { type: "Activity", id: arg.id },
      ],
    }),
    getActivities: builder.query<GetActivitiesResponse, GetActivitiesArgs>({
      query: args => ({ func: ActivitiesService.getActivities, args }),
      providesTags: (_result, _error, arg) => [
        {
          type: "Activities",
          id: `${arg.page_size}-${arg.page}-${arg.location}-${arg.category}-${arg.subCategory}`,
        },
      ],
    }),
  }),
});

export default activitiesApi;
